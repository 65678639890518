<template>
  <div class="container mt-3" v-loading="loading">
    <div class="row border-bottom py-3">
      <div class="col-md-3">
        <p class="mb-0 robo-16-500 txt-black">{{ $t('lbl_date') }}</p>
      </div>
      <div class="col-md-9">
        <p class="mb-0 robo-16-500 txt-black">{{ $t('lbl_time') }}</p>
      </div>
    </div>
    <div
      class="row border-bottom py-3"
      :class="loading ? 'disabled' : ''"
      v-for="day in days"
      :key="day.day"
    >
      <div class="col-md-3">
        <div class="d-flex align-items-center">
          <input
            class="cursor-pointer mr-2"
            type="checkbox"
            v-model="day.status"
            :id="'daycheckbox_' + day.day"
          />
          <label
            class="mb-0 robo-16-500 txt-black cursor-pointer"
            :for="'daycheckbox_' + day.day"
          >{{ $t(`${day.name}`) }}</label>
        </div>
      </div>
      <div
        class="col-md-9 row"
        :set="(sessions = day.sessions)"
        :class="!day.status ? 'disabled' : ''"
      >
        <div class="col-6">
          <div class="mt-2" v-for="(session, index) in day.sessions" :key="index">
            <div class="row d-flex align-items-center" :class="!session.status ? 'disabled' : ''">
              <div class="col-lg-5">
                <input type="time" v-model="session.start_time" class="form-control" />
              </div>
              <div class="col-lg-5">
                <input type="time" v-model="session.end_time" class="form-control" />
              </div>
              <div class="col-lg-2" style="line-height: 44px">
                <img
                  src="../../../../public/assets/images/icon/icon-delete.svg"
                  style="height: 24px; width: 24px; cursor: pointer"
                  @click="deleteWorktime(day.id, index)"
                />
              </div>
              <div class="col-12 text-center">
                <span class="font-size-10 text-danger">{{ session.error }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 offset-6 text-center">
          <a
            class="txt-pri robo-20-400"
            href="javascript:void(0)"
            @click="addWorktime(day.id, day.day)"
            v-if="day.status"
            style="text-decoration: none"
          >+ {{ $t('lbl_add') }}</a>
        </div>
      </div>
      <p v-if="day.error.cr_name && day.sessions.length" class="mb-0 offset-3 col-9 text-danger">
        {{
        $t('fm_doctor_has_worktime',{
        name:doctor_name,
        duration:day.error.duration,
        room:day.error.cr_name
        })}}
      </p>
    </div>
    <div class="text-right mt-4">
      <button
        @click="createWorktime"
        type="button"
        class="btn bg-pri bd-pri text-white px-5"
        :disabled="loading"
      >
        <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
        Lưu
      </button>
    </div>
  </div>
</template>
<script>
import responseHanlder from '../../../utils/responseHanlder.js'
import appUtils from '../../../utils/appUtils'

export default {
  props: ['doctor_name', 'cr_id', 'doctorWorkTimeSettingId', 'slotDuration'],
  name: 'CRWorkTime',
  data () {
    return {
      days: [
        {
          id: 0,
          day: 1,
          name: 'weeks.monday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 1,
          day: 2,
          name: 'weeks.tuesday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 2,
          day: 3,
          name: 'weeks.wednesday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 3,
          day: 4,
          name: 'weeks.thursday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 4,
          day: 5,
          name: 'weeks.friday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 5,
          day: 6,
          name: 'weeks.saturday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 6,
          day: 0,
          name: 'weeks.sunday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        }
      ],
      new_worktimes: null,
      loading: false,
      responseHanlder
    }
  },
  mounted () {
    this.getWorktime()
  },
  methods: {
    addWorktime (id, day) {
      let self = this
      if (isNaN(id) || id > 6 || id < 0) return
      self.days[id].sessions.push({
        day: day,
        start_time: '08:00',
        end_time: '17:00',
        error: '',
        status: 1
      })
    },
    deleteWorktime (id, idx) {
      this.days[id].error = {
        cr_name: '',
        duration: ''
      }
      this.days[id].sessions.splice(idx, 1)
    },
    async getWorktime () {
      let self = this

      this.days = [
        {
          id: 0,
          day: 1,
          name: 'weeks.monday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 1,
          day: 2,
          name: 'weeks.tuesday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 2,
          day: 3,
          name: 'weeks.wednesday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 3,
          day: 4,
          name: 'weeks.thursday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 4,
          day: 5,
          name: 'weeks.friday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 5,
          day: 6,
          name: 'weeks.saturday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        },
        {
          id: 6,
          day: 0,
          name: 'weeks.sunday',
          status: 0,
          sessions: [],
          error: {
            cr_name: '',
            duration: ''
          }
        }
      ]
      // let doctor_id = this.doctor_id
      // if (!doctor_id) return
      self.loading = true
      // let params = {
      //   cr_id: this.cr_id,
      //   doctor_id: this.doctor_id
      // }

      await self.$rf
        .getRequest('DoctorRequest')
        .getCRWorkTimesV2(this.doctorWorkTimeSettingId)
        .then(resp => {
          if (resp?.data.data) {
            resp.data.data.forEach(wt => {
              let index =
                wt.week_day === 1
                  ? 0
                  : wt.week_day === 2
                    ? 1
                    : wt.week_day === 3
                      ? 2
                      : wt.week_day === 4
                        ? 3
                        : wt.week_day === 5
                          ? 4
                          : wt.week_day === 6
                            ? 5
                            : wt.week_day === 0
                              ? 6
                              : -1
              if (index < 0) return
              const timeRanges = Object.keys(
                appUtils.groupByKey(wt.working_slot, 'original_range', {
                  omitKey: true
                })
              )
              timeRanges.forEach(x => {
                const timeRangeArray = x.split('-')
                self.days[index].sessions.push({
                  day: wt.week_day,
                  start_time: self.formatHM(timeRangeArray[0]),
                  end_time: self.formatHM(timeRangeArray[1]),
                  error: '',
                  status: wt.week_day_status
                })
                self.days[index].status = wt.week_day_status
              })
            })
          }
        })
        .catch(e => {
          console.log(e)
          responseHanlder._errorHandler(e)
        })
        .finally(() => {
          self.loading = false
        })
    },
    validate () {
      let self = this
      let validated = true
      let params = []
      self.new_worktimes = null
      self.days.forEach((day, index) => {
        if (day.sessions) {
          day.sessions.forEach((ss, index) => {
            if (ss.start_time >= ss.end_time) {
              ss.error = this.$t(
                'lbl_the_start_time_must_be_before_the_end_time_'
              )
              validated = false
            } else if (self.blockedTime(ss, day.sessions, index)) {
              ss.error = this.$t('lbl_overlap_in_working_hours_')
              validated = false
            } else {
              ss.error = ''
              ss.status = day.status ? 1 : 0
              params.push(ss)
            }
          })
        }
      })
      self.new_worktimes = params
      return validated
    },
    async createWorktime () {
      let self = this
      if (!self.validate()) return
      self.loading = true
      // let params = {
      //   cr_id: this.cr_id,
      //   doctor_id: this.doctor_id
      // }
      const payloadWorkTimes = this.new_worktimes.map(x => ({
        Weekday: x.day,
        Start: x.start_time,
        End: x.end_time
      }))
      // if (self.new_worktimes) {
      //   params.worktimes = self.new_worktimes
      // }

      const groupArray = appUtils.groupByKey(payloadWorkTimes, 'Weekday', {
        omitKey: true
      })
      const weekdaysArray = Object.keys(groupArray)
      const convertWeekDayArray = weekdaysArray.map(x => ({
        Weekday: parseInt(x),
        Slots: groupArray[`${x}`],
        WeekdayStatus: this.new_worktimes.find(i => i.day === parseInt(x))
          .status
      }))
      const params = {
        DoctorworktimesettingID: this.doctorWorkTimeSettingId,
        SlotDuration: this.slotDuration,
        Weekdays: convertWeekDayArray
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .createCRWorkTimesV2(params)
        .then(resp => {
          self.$message({
            message: 'Thêm mới thành công',
            type: 'success'
          })
          self.$emit('refresh')
        })
        .catch(e => {
          if (e.response.data.Error) {
            const errResponse = e.response.data.Error
            self.days.find(day => day.day === errResponse.week_day).error = {
              cr_name: errResponse.cr_name,
              duration: errResponse.duration
            }
          }
          responseHanlder._errorHandler(e)
        })
        .finally(() => {
          self.loading = false
        })
    },
    // utils
    formatHM (time) {
      return window.moment('2021-06-26 ' + time).format('HH:mm')
    },
    newDateToTime (time) {
      return window.moment('2021-06-26 ' + time).unix()
    },
    blockedTime (wt, session, index) {
      if (session) {
        let start_time = wt.start_time
        let end_time = wt.end_time
        // so sánh thời gian bắt đầu với thời gian đã bị block
        return session.some((br, idx) => {
          let start = br.start_time
          let end = br.end_time
          let valid =
            (start_time === start ||
              (start_time > start && start_time < end) ||
              end_time === end ||
              (end_time > start && end_time < end) ||
              (start_time > start && end_time < end) ||
              (start_time < start && end_time > end)) &&
            idx !== index
          return valid
        })
      }
    }
  }
}
</script>
<style lang="css" scoped>
.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
</style>