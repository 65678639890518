<template>
  <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" append-to-body width="600px">
    <div class="container">
      <div
        class="text-center text-2xl font-semibold mb-3"
      >{{ $t('lbl_add_doctors__healthcare_professionals') }}</div>

      <el-form v-if="form" :model="form" ref="formRef">
        <div class>
          <el-form-item
            :label="$t('lbl_doctor')"
            prop="doctor_id"
            :rules="[{ required: true, message: $t('lbl_this_field_is_required'), trigger: 'blur' }]"
          >
            <el-select
              v-model="form.doctor_id"
              class="w-full"
              :placeholder="$t('lbl_select_doctor')"
              @change="getDoctors"
            >
              <el-option
                v-for="item in listDoctors"
                :key="item.id + 'md'"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('lbl_note')" prop="note">
            <el-input class="w-full" v-model="form.note" autocomplete="off"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="flex justify-end">
        <el-button class="border-0 text-base" @click="handleClose">{{ $t('lbl_cancel') }}</el-button>
        <el-button
          class="text-white w-20 text-sm"
          type="primary"
          @click="createConsultingRoomDoctor"
        >{{ $t('lbl_save') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
  
<script>
export default {
  name: 'ModalAddDoctor',
  props: ['members'],
  data () {
    return {
      dialogVisible: false,
      form: {
        doctor_id: null,
        note: null
      },
      crID: null,
      doctors: [],
      doctor: ''
    }
  },
  computed: {
    listDoctors () {
      let newDtrs = [...this.doctors]
      return newDtrs.filter(
        d => !this.members?.find(m => m.doctor_id === d.id)
      )
    }
  },
  mounted () {
    this.getDoctors()
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$nextTick(() => this.cancel())
    },
    openDialog (id) {
      this.dialogVisible = true
      this.$nextTick(() => {
        if (id) {
          this.crID = id
        }
      })
    },
    cancel () {
      this.crID = null
      this.doctor = ''
      this.$refs.formRef.resetFields()
    },
    async createConsultingRoomDoctor () {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        try {
          // const param = {
          //   cr_id: this.crID,
          //   ...this.form
          // }
          const param = {
            consultingroomid: this.crID,
            doctorid: this.form.doctor_id,
            note: this.form.note || ''
            // ...this.form
          }
          await this.$rf
            .getRequest('DoctorRequest')
            .createConsultingRoomDoctorV2(param)
            .then(res => {
              if (res?.data) {
                this.$message({
                  message: this.$t('lbl_add_success'),
                  type: 'success'
                })
                this.handleClose()
                this.$emit('refresh', this.crID)
              }
            })
        } catch (e) {
          this.$message({
            message: this.$t('lbl_add_fail'),
            type: 'warning'
          })
          console.log(e)
        }
      })
    },
    async getDoctors () {
      try {
        const clinic_id = this.$clinicId
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
        this.doctors = res.data?.data
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item__label {
    line-height: 26px;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .el-dialog {
    border-radius: 10px;
  }
}
</style>