<template>
  <div>
    <el-dialog :visible.sync="isOpen" append-to-body width="70%" top="50px">
      <template slot="title">
        <div class="fs-20 font-bold uppercase text-black">
          {{ $t("Chọn dịch vụ") }}
        </div>
      </template>

      <div class="cs-table">
        <div class="flex flex-col md:flex-row gap-4 items-center mb-4">
          <div class="flex-1">
            <label class="block">{{ $t("Tìm kiếm") }}</label>

            <el-input
              class="cs-input"
              :placeholder="$t('Nhập tên dịch vụ, mã dịch vụ,...')"
              clearable
              v-model="searchValue"
              @input="handleDebounce"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
          <div class="flex-1">
            <label class="block">{{ $t("Loại Dịch vụ") }}</label>
            <el-select
              class="w-full cs-select"
              clearable
              v-model="service_type"
              placeholder="Chọn loại dịch vụ"
              @change="() => handleGetServiceLocal()"
            >
              <el-option
                v-for="(type, index) in SERVICE_TYPE.filter(
                  (item) => item.value != 1
                )"
                :key="index"
                :label="type.label"
                :value="type.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <el-table :data="serviceLocals" border style="width: 100%">
          <el-table-column align="center" width="55">
            <template slot-scope="scope">
              <el-radio
                class="cs-radio"
                v-model="selectedRowId"
                :label="scope.row.id"
                >{{
              }}</el-radio>
            </template>
          </el-table-column>
          <el-table-column
            prop="service_catalog.services_code"
            :label="$t('Mã dịch vụ')"
          >
          </el-table-column>
          <el-table-column
            prop="local_name"
            min-width="300"
            :label="$t('Tên dịch vụ')"
          >
          </el-table-column>
          <el-table-column
            prop="type"
            :formatter="
              (row) =>
                handleGetServiceType(
                  row.service_catalog && row.service_catalog.service_type
                )
            "
            :label="$t('Loại dịch vụ')"
          >
          </el-table-column>
          <el-table-column
            align="right"
            class="text-right"
            :formatter="
              (row) => {
                return row.local_price
                  ? appUtils.numberFormat(row.local_price, 'VND')
                  : '';
              }
            "
            label="Giá dịch vụ"
          ></el-table-column>
        </el-table>
      </div>
      <AppPagination
        :paginationProps="pagination"
        @onChangePagination="handleGetServiceLocal"
      />

      <div class="flex flex-col md:flex-row items-center justify-end mt-4">
        <el-button @click="handleClose">{{ $t("lbl_cancel") }}</el-button>
        <el-button
          :disabled="!selectedRowId"
          type="primary"
          @click="handleSubmit"
          >{{ $t("lbl_save") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import appUtils from '../../../utils/appUtils'
import { AppPagination } from '@/components/Common'
import { SERVICE_TYPE } from '../../../utils/constants'
import { debounce } from 'lodash'

export default {
  name: 'ModalConfigServiceForDoctor',
  components: { AppPagination },
  data () {
    return {
      isOpen: false,
      searchValue: '',
      service_type: null,
      serviceTypes: [],
      serviceLocals: [],
      appUtils,
      selectedRowId: null,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      SERVICE_TYPE,
      doctorCR: {}
    }
  },
  methods: {
    handleOpen (data) {
      this.isOpen = true
      this.doctorCR = data
      this.selectedRowId = this.doctorCR?.ServiceLocalId
      this.handleGetServiceLocal()
    },
    handleClose () {
      this.isOpen = false
      this.searchValue = ''
      this.service_type = null
      this.selectedRowId = null
    },
    async handleGetServiceLocal (data) {
      if (!this.$globalClinic?.id || !this.$globalClinic?.org_id) return

      try {
        const params = {
          order: 'desc',
          sort_by: 'created_at',
          page_num: data?.currentPage || this.pagination.currentPage,
          page_size: data?.pageSize || this.pagination.pageSize,
          keyword: this.searchValue,
          type: this.service_type,
          ws_id: this.$globalClinic?.id,
          group_id: 5 // Dịch vụ Khám/Tư vấn
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceLocals(params)
        const responseData = response.data || {}

        this.serviceLocals = responseData?.data || []
        this.pagination = {
          currentPage: responseData?.page?.page_num || 1,
          pageSize: responseData?.page?.page_size || 10,
          totalItems: responseData?.page?.total || 0
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleGetServiceType (type) {
      return SERVICE_TYPE.find((item) => item?.value === type)?.label || ''
    },
    handleDebounce: debounce(function () {
      this.handleGetServiceLocal()
    }, 500),
    async handleSubmit () {
      if (!this.doctorCR?.ID || !this.selectedRowId) return

      try {
        const hasConfirm = await this.handleConfirm()

        if (!hasConfirm) return

        const params = {
          doctor_work_time_id: this.doctorCR.ID,
          service_local_id: this.selectedRowId
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .configServiceForDoctorCR(params)

        if (response.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('Thiết lập dịch vụ cho bác sĩ thành công')
          })

          this.$emit('onRefresh')
          this.handleClose()
        }
      } catch (error) {
        console.log(error)
        this.$message({
          type: 'error',
          message: this.$t('Có lỗi xảy ra khi thiết lập dịch vụ cho bác sĩ')
        })
      }
    },
    async handleConfirm () {
      if (!this.selectedRowId) return false
      const doctorName = this.doctorCR?.Doctor?.User?.name
      const serviceName =
        this.serviceLocals?.find((item) => item?.id === this.selectedRowId)
          ?.local_name || ''

      const hasConfirm = await this.$confirm(
        `Bạn chắc chắn muốn thiết lập dịch vụ <strong>${serviceName}</strong> cho bác sĩ <strong>${doctorName}</strong> không?`,
        'Xác nhận',
        {
          dangerouslyUseHTMLString: true,
          cancelButtonText: this.$t('lbl_cancel'),
          confirmButtonText: this.$t('lbl_confirm')
        }
      ).catch(() => {})

      return hasConfirm
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-input {
    .el-input__inner {
      border: 1px solid #dcdfe6 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      background-color: white !important;
    }
  }

  .cs-select {
    .el-input__inner {
      border: 1px solid #dcdfe6 !important;
      background-color: white !important;
    }
  }

  .cs-table * {
    color: rgb(50, 50, 50) !important;
  }

  .cs-radio {
    .el-radio__label {
      display: none !important;
    }
  }
}
</style>