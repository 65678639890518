<template>
  <div class="container">
    <div v-if="isOwnerWorkspace">
      <BankInformation></BankInformation>
    </div>
    <div v-else>
      <p class="fs-16 fw-500 text-center my-5">Bạn không có quyền truy cập</p>
    </div>
  </div>
</template>

<script>
import TypeBarClinicManage from '../../components/Doctor/TypeBarClinicManage.vue'
import BankInformation from '../../components/BankInformation.vue'

export default {
  name: 'ClinicBankManage',
  components: { TypeBarClinicManage, BankInformation },
  computed: {

    isOwnerWorkspace () {
      let clinic = this.$globalClinic
      let user = this.$user
      if (!clinic || !clinic.id || !user || !user.doctor) return
      return clinic.doctor_id === user.doctor.id
    }
  }
}
</script>

<style scoped>
</style>