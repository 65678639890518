<template>
  <div class="container">
    <!-- <TypeBarClinicManage :type="'clinic-settings'" /> -->
    <div v-if="isOwnerWorkspace" class="py-3">
      <ClinicSetting></ClinicSetting>
    </div>
  </div>
</template>

<script>
import TypeBarClinicManage from '../../components/Doctor/TypeBarClinicManage.vue'
import ClinicSetting from '../../components/Clinic/ClinicSetting.vue'

import appUtils from '../../utils/appUtils'
export default {
  name: 'ClinicInfoManage',
  components: { TypeBarClinicManage, ClinicSetting },
  computed: {
    isOwnerWorkspace () {
      let clinic = this.$globalClinic
      let user = appUtils.getLocalUser()
      if (!clinic || !clinic.id || !user || !user.doctor) return
      return clinic.doctor_id === user.doctor.id
    }
  },
  mounted () {
    if (!this.isOwnerWorkspace) this.$router.push({name: 'ClinicPersonnelManage'})
  }
}
</script>

<style scoped>
</style>