<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    append-to-body
    width="600px"
  >
    <div class="container">
      <div class="text-center text-2xl font-semibold mb-3">
        {{
          $t(isCreate ? "lbl_add_consulting_room" : "lbl_edit_consulting_room")
        }}
      </div>

      <el-form v-if="form" :model="form" ref="formRef">
        <div class>
          <el-form-item
            :label="$t('lbl_consulting_room_name')"
            prop="Name"
            :rules="[
              {
                required: true,
                message: $t('lbl_this_field_is_required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model="form.Name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('lbl_address')"
            prop="Address"
            :rules="[
              {
                required: true,
                message: $t('lbl_this_field_is_required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              class="w-full"
              v-model="form.Address"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <div class="row">
            <el-form-item
              class="col-md-6"
              :label="$t('lbl_consulting_duration__minutes_short')"
              prop="Slot_Duration"
              :rules="[
                {
                  required: true,
                  message: $t('lbl_this_field_is_required'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="form.Slot_Duration"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="col-md-6"
              :label="$t('lbl_consulting_fee__vnd_short')"
              prop="Slot_Price"
              :rules="[
                {
                  required: false,
                  message: $t('lbl_this_field_is_required'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-input v-model="form.Slot_Price" autocomplete="off"></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <el-checkbox
              label="Áp dụng chi phí khám cho tất cả Bác sĩ"
              name="type"
              v-model="form.isUseRoomPrice"
            ></el-checkbox>
          </el-form-item>
        </div>
      </el-form>

      <div class="flex justify-end">
        <el-button class="border-0 text-base" @click="handleClose">{{
          $t("lbl_cancel")
        }}</el-button>
        <el-button
          class="text-white w-20 text-sm"
          type="primary"
          @click="handleSubmit"
          >{{ $t("lbl_save") }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>
  
<script>
import _ from 'lodash'
import { USE_ROOM_PRICE_CR } from '../../../utils/constants'
export default {
  name: 'ModalAddConsultingRoom',
  data () {
    return {
      dialogVisible: false,
      form: {},
      isCreate: true
    }
  },
  computed: {},
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$nextTick(() => this.cancel())
    },
    openDialog (obj) {
      this.dialogVisible = true
      this.$nextTick(() => {
        if (obj) {
          const formatObj = {
            ...obj,
            isUseRoomPrice: obj?.IsUsingRoomPrice === USE_ROOM_PRICE_CR.isUse
          }

          this.form = _.cloneDeep(formatObj)
          this.isCreate = false
        }
      })
    },
    cancel () {
      this.form = {}
      this.$refs.formRef.resetFields()
    },
    handleSubmit () {
      if (this.isCreate) this.createConsultingRoom()
      else this.updateConsultingRoom()
    },
    async createConsultingRoom () {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return
        try {
          const tranferForm = {
            name: this.form.Name,
            address: this.form.Address,
            slot_price: Number(this.form.Slot_Price),
            slot_duration: Number(this.form.Slot_Duration),
            is_using_room_price: this.form.isUseRoomPrice
              ? USE_ROOM_PRICE_CR.isUse
              : USE_ROOM_PRICE_CR.isNotUse
          }
          const param = {
            clinic_id: this.$globalClinic.id,
            ...tranferForm
          }
          await this.$rf
            .getRequest('DoctorRequest')
            .createConsultingRoomV2(param)
            .then((res) => {
              if (res?.data) {
                this.$message({
                  message: this.$t('lbl_add_success'),
                  type: 'success'
                })
                this.handleClose()
                this.$emit('refresh')
              }
            })
        } catch (e) {
          this.$message({
            message: this.$t('lbl_add_fail'),
            type: 'warning'
          })
          console.log(e)
        }
      })
    },
    async updateConsultingRoom () {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return
        try {
          const tranferForm = {
            name: this.form.Name,
            address: this.form.Address,
            slot_price: Number(this.form.Slot_Price),
            slot_duration: Number(this.form.Slot_Duration),
            is_using_room_price: this.form.isUseRoomPrice
              ? USE_ROOM_PRICE_CR.isUse
              : USE_ROOM_PRICE_CR.isNotUse
          }
          const param = {
            clinic_id: this.$globalClinic.id,
            ...tranferForm
          }
          await this.$rf
            .getRequest('DoctorRequest')
            .updateConsultingRoomV2(this.form.ID, param)
            .then((res) => {
              if (res?.status === 200) {
                this.$message({
                  message: this.$t('lbl_update_success'),
                  type: 'success'
                })
                this.handleClose()
                this.$emit('refresh', this.form.ID)
              }
            })
        } catch (e) {
          this.$message({
            message: this.$t('lbl_udpate_fail'),
            type: 'warning'
          })
          console.log(e)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item__label {
    line-height: 26px;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .el-dialog {
    border-radius: 10px;
  }
}
</style>