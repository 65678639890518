<template>
  <div class="container bg-white h-full">
    <div class="pt-3">
      <TemplateManagement></TemplateManagement>
    </div>
  </div>
</template>

<script>
import TypeBarClinicManage from '../../components/Doctor/TypeBarClinicManage.vue'
import TemplateManagement from '../../components/Clinic/TemplateManagement'

export default {
  name: 'ClinicDiseaseTempManage',
  components: { TypeBarClinicManage, TemplateManagement }
}
</script>

<style scoped>
</style>
