<template>
  <div class="container py-4">
    <TypeBarClinicManage :type="'ws-mgmt'" />
    <div class="pt-3">
      <div class="flex justify-between items-center">
        <div class="fs-20 fw-700 txt-grey-900">
          {{ $t("lbl_consulting_room") }}
        </div>
        <div v-if="isOwnerWorkspace">
          <el-button type="primary" @click="addConsultingRoom"
            >+ {{ $t("lbl_add_consulting_room") }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="consultingRooms"
        style="width: 100%"
        row-class-name="text-black text-sm cursor-pointer"
        header-cell-class-name="text-black text-sm font-semibold"
        @row-click="viewDetailConsultingRoom"
      >
        <el-table-column
          prop="name"
          :label="$t('lbl_consulting_room_name')"
        ></el-table-column>
        <el-table-column
          prop="address"
          :label="$t('lbl_address')"
        ></el-table-column>
        <el-table-column
          prop="slot_duration"
          :label="$t('lbl_consulting_duration__minutes_')"
          :formatter="formatSlotTime"
        ></el-table-column>
        <el-table-column
          prop="slot_price"
          :label="$t('lbl_consulting_fee__vnd_')"
          :formatter="formatPrice"
        ></el-table-column>
        <!-- <el-table-column
          prop="status"
          label="Trạng thái"
          :formatter="getStatus"
        >
        </el-table-column>-->
      </el-table>

      <!-- <Pagination
        v-show="consultingRooms && consultingRooms.count"
        :items="consultingRooms"
        :current_page="current_page"
        @onRefresh="getConsultingRooms"
      ></Pagination>-->
      <AppPagination
        :paginationProps="pagination"
        @onChangePagination="getConsultingRooms"
      />
    </div>
    <ModalAddConsultingRoom
      ref="ModalAddConsultingRoom"
      @refresh="getConsultingRooms"
    />

    <ModalViewConsultingRoom
      :isOwnerWorkspace="isOwnerWorkspace"
      ref="ModalViewConsultingRoom"
      @refresh="getConsultingRooms"
    />
  </div>
</template>

<script>
import TypeBarClinicManage from '../../../components/Doctor/TypeBarClinicManage.vue'
import ModalAddConsultingRoom from './ModalAdd.vue'
import ModalViewConsultingRoom from './ModalView.vue'
import Pagination from '../../../components/Pagination'
import appUtils from '../../../utils/appUtils'
import { AppPagination } from '@/components/Common'

export default {
  name: 'ClinicConsultingRooms',
  components: {
    Pagination,
    TypeBarClinicManage,
    ModalAddConsultingRoom,
    ModalViewConsultingRoom,
    AppPagination
  },
  data () {
    return {
      consultingRooms: [],
      loading: false,
      params: {
        page: 1,
        limit: 10
      },
      current_page: 1,
      appUtils,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      }
    }
  },
  mounted () {
    this.getConsultingRooms()
  },
  computed: {
    isOwnerWorkspace () {
      const clinic = this.$globalClinic
      const user = this.$user
      if (!clinic || !clinic.id || !user || !user.doctor) return
      return clinic.doctor_id === user.doctor.id
    }
  },
  methods: {
    formatSlotTime (row) {
      return this.$t('fm_minutes', {
        minutes: row.slot_duration || ''
      })
    },
    formatPrice (row) {
      if (row?.is_using_room_price === 1) return 'Theo yêu cầu'
      return appUtils.formatToCurrency({ amount: row.slot_price })
    },
    getStatus (row) {
      if (row.status) return 'Hoạt động'
      else return 'Tạm dừng'
    },
    async getConsultingRooms (dataPage) {
      this.loading = true
      this.current_page = parseInt(this.$route.query.page) || 1
      const param = {
        ...this.params,
        page_size: dataPage?.pageSize || this.pagination.pageSize,
        page_num: dataPage?.currentPage || this.pagination.currentPage,
        ws_id: this.$globalClinic?.id
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getConsultingRoomsV3(param)
        .then((r) => {
          const responseData = r.data || {}

          this.consultingRooms = responseData?.data || []
          this.current_page = responseData?.page?.page_num
          this.pagination = {
            currentPage: responseData?.page?.page_num || 1,
            pageSize: responseData?.page?.page_size || 10,
            totalItems: responseData?.page?.total || 0
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    addConsultingRoom () {
      this.$refs.ModalAddConsultingRoom.openDialog()
    },
    viewDetailConsultingRoom (row) {
      this.$refs.ModalViewConsultingRoom.openDialog(row.id)
    }
  }
}
</script>
