var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"before-close":_vm.handleClose,"append-to-body":"","width":"600px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text-center text-2xl font-semibold mb-3"},[_vm._v(" "+_vm._s(_vm.$t(_vm.isCreate ? "lbl_add_consulting_room" : "lbl_edit_consulting_room"))+" ")]),(_vm.form)?_c('el-form',{ref:"formRef",attrs:{"model":_vm.form}},[_c('div',{},[_c('el-form-item',{attrs:{"label":_vm.$t('lbl_consulting_room_name'),"prop":"Name","rules":[
            {
              required: true,
              message: _vm.$t('lbl_this_field_is_required'),
              trigger: 'blur',
            },
          ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.Name),callback:function ($$v) {_vm.$set(_vm.form, "Name", $$v)},expression:"form.Name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('lbl_address'),"prop":"Address","rules":[
            {
              required: true,
              message: _vm.$t('lbl_this_field_is_required'),
              trigger: 'blur',
            },
          ]}},[_c('el-input',{staticClass:"w-full",attrs:{"autocomplete":"off"},model:{value:(_vm.form.Address),callback:function ($$v) {_vm.$set(_vm.form, "Address", $$v)},expression:"form.Address"}})],1),_c('div',{staticClass:"row"},[_c('el-form-item',{staticClass:"col-md-6",attrs:{"label":_vm.$t('lbl_consulting_duration__minutes_short'),"prop":"Slot_Duration","rules":[
              {
                required: true,
                message: _vm.$t('lbl_this_field_is_required'),
                trigger: 'blur',
              },
            ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.Slot_Duration),callback:function ($$v) {_vm.$set(_vm.form, "Slot_Duration", $$v)},expression:"form.Slot_Duration"}})],1),_c('el-form-item',{staticClass:"col-md-6",attrs:{"label":_vm.$t('lbl_consulting_fee__vnd_short'),"prop":"Slot_Price","rules":[
              {
                required: false,
                message: _vm.$t('lbl_this_field_is_required'),
                trigger: 'blur',
              },
            ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.Slot_Price),callback:function ($$v) {_vm.$set(_vm.form, "Slot_Price", $$v)},expression:"form.Slot_Price"}})],1)],1),_c('el-form-item',[_c('el-checkbox',{attrs:{"label":"Áp dụng chi phí khám cho tất cả Bác sĩ","name":"type"},model:{value:(_vm.form.isUseRoomPrice),callback:function ($$v) {_vm.$set(_vm.form, "isUseRoomPrice", $$v)},expression:"form.isUseRoomPrice"}})],1)],1)]):_vm._e(),_c('div',{staticClass:"flex justify-end"},[_c('el-button',{staticClass:"border-0 text-base",on:{"click":_vm.handleClose}},[_vm._v(_vm._s(_vm.$t("lbl_cancel")))]),_c('el-button',{staticClass:"text-white w-20 text-sm",attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t("lbl_save")))])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }